import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import BackgroundVideo from "./BackgroundVideo";

function Layout({ children }) {
  return (
    <main className="min-h-screen w-full">
      <Header />
      <BackgroundVideo />
      <main>{children} </main>
      <Footer />
    </main>
  );
}

export default Layout;
