import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "./CartContext";
import CartModal from "./CartModal";
import logo from "../imgs/logo.svg";
import openIcon from "../imgs/SVG/open.svg";
import closeIcon from "../imgs/SVG/close.svg";

export default function Header() {
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const { getCartCount } = useCart();

  const toggleMenu = () => {
    setisMenuOpen(!isMenuOpen);
  };

  const handleCartClick = () => {
    setShowCartModal(!showCartModal);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <nav
        className={`py-2 z-50 transition-all duration-300 w-full
          ${isScrolled ? "fixed top-0 " : "absolute top-0"}
          ${isMenuOpen ? "h-60" : "h-16"}`}
      >
        <div className="md:max-w-3xl mx-auto relative rounded-lg px-4 md:px-0">
          <div className="flex justify-between items-center md:py-3 px-4 bg-black bg-opacity-10 backdrop-blur-lg drop-shadow-lg shadow-xl rounded-lg gradient-border">
            {/* Left section with two links */}
            <div className="md:flex space-x-5 hidden">
              <Link className="text-white font-vcr-mono text-xl" to="/shop">
                SHOP
              </Link>
              <Link className="text-white font-vcr-mono text-xl" to="/about">
                ABOUT
              </Link>
            </div>

            {/* Hamburger menu */}
            <div className="md:hidden">
              <button onClick={toggleMenu}>
                <img
                  src={isMenuOpen ? closeIcon : openIcon}
                  alt="Menu icon"
                  className="w-12 h-12"
                />
              </button>
            </div>

            {/* Center logo */}
            <div className="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center">
              <Link className="text-white font-vcr-mono text-2xl" to="/">
                <img
                  src={logo}
                  alt="DNMGD Official Logo"
                  className="w-32 md:w-40"
                />
              </Link>
            </div>

            {/* Right section with cart */}
            <div className="flex space-x-5">
              <Link
                className="text-white font-vcr-mono text-xl md:inline-flex hidden"
                to="/"
              >
                ARCHIVE
              </Link>
              <button
                className="text-white font-vcr-mono md:text-xl text-md cursor-pointer"
                onClick={handleCartClick}
              >
                BAG(<span>{getCartCount()}</span>)
              </button>
            </div>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="flex flex-col items-start space-y-4 py-4 px-4 bg-black bg-opacity-10 backdrop-blur-lg drop-shadow-lg shadow-xl md:hidden rounded-b-lg transition-all duration-300 ease-in-out transform">
              <Link
                className="text-white font-vcr-mono text-xl border-b border-white pb-2 w-full"
                to="/shop"
              >
                SHOP
              </Link>
              <Link
                className="text-white font-vcr-mono text-xl border-b border-white pb-2 w-full"
                to="/about"
              >
                ABOUT
              </Link>
              <Link
                className="text-white font-vcr-mono text-xl border-b border-white pb-2 w-full"
                to="/discover"
              >
                ARCHIVE
              </Link>
              <p className="font-bold text-white">FOLLOW US</p>
            </div>
          )}
        </div>
      </nav>

      {/* Cart Modal */}
      {showCartModal && <CartModal onClose={() => setShowCartModal(false)} />}
    </>
  );
}
