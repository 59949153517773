import React from "react";
import { Link } from "react-router-dom";
import { ArrowUpRight } from "lucide-react";

export default function Footer() {
  return (
    <footer className="bg-white text-black font-Neuehaasdisplay font-light mt-20">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mx-8 py-5">
        <div className="font-vcr-mono flex flex-col space-y-2">
          <p className="font-bold">COMPANY</p>
          <Link to={"/about"}>ABOUT</Link>
          <Link to={"/shop"}>SHOP</Link>
          <Link to={"/discover"}>DISCOVER</Link>
        </div>
        <div className="font-vcr-mono flex flex-col space-y-2">
          <p className="font-bold">SUPPORT</p>
          <a href="mailto:support@dnmgd.com" className="flex">
            SUPPORT@DNMGD.COM
            <ArrowUpRight />
          </a>
          <Link to={"/terms"}>TERMS & CONDITION</Link>
          <Link to={"/privacy"}>PRIVACY</Link>
        </div>

        <div className="hidden sm:block col-span-2 md:col-span-1">
          <p className="font-vcr-mono font-bold uppercase text-center">
            Sign up for DNMGD updates
          </p>
          <form className="flex items-center space-x-2">
            <input
              type="email"
              placeholder="Enter e-mail address"
              className="border-b py-2 w-full placeholder:text-black placeholder:text-center border-b-black"
            />
          </form>
        </div>
      </div>
      <div className="bg-black h-[1px] mb-3 mx-5"></div>
      <p className="mx-5 md:py-5 py-5 text-xs md:text-base text-center font-vcr-mono uppercase">
        © 2024 DNMGD. ENGINEERED by{" "}
        <a
          href="mailto:bullshtstudios@gmail.com"
          className="underline md:text-base text-xs"
        >
          Bullshit Studios
        </a>
      </p>
    </footer>
  );
}
