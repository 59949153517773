import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

import { ArrowUpRight } from "lucide-react";
import { ADMIN_URL, BASE_URL } from "../config/constants";

const ProductCarousel = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getMerch();
  }, []);

  const getMerch = () => {
    let url = `${BASE_URL}/products/recent`;
    axios
      .get(url)
      .then((response) => {
        let data = response.data;
        setProducts(data.data);
      })
      .catch((error) =>
        toast.error(
          "An error occured, please check your connection and try again"
        )
      );
  };

  // Update isMobile state based on window width
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // 768px is the md breakpoint in Tailwind
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Dynamically set visible products based on screen size
  const visibleProducts = isMobile ? 1 : 3;
  const maxStartIndex = products.length - visibleProducts;

  const scrollLeft = () => {
    setStartIndex((prev) => Math.max(0, prev - 1));
  };

  const scrollRight = () => {
    setStartIndex((prev) => Math.min(maxStartIndex, prev + 1));
  };

  return (
    <div className="mt-5 md:mx-5 mx-1 p-5">
      <div className="flex justify-between font-vcr-mono text-white">
        <p className=" text-2xl">NEW ARRIVALS</p>
        <Link to={"/shop"} className="flex ">
          SHOP
          <ArrowUpRight />
        </Link>
      </div>

      <div className="relative">
        {/* Navigation Buttons */}
        <button
          onClick={scrollLeft}
          disabled={startIndex === 0}
          className={`absolute left-0 top-1/2 -translate-y-1/2 z-10 p-2 bg-black bg-opacity-50 text-white rounded-r ${
            startIndex === 0
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-opacity-70"
          }`}
        >
          <ChevronLeft size={24} />
        </button>

        <button
          onClick={scrollRight}
          disabled={startIndex === maxStartIndex}
          className={`absolute right-0 top-1/2 -translate-y-1/2 z-10 p-2 bg-black bg-opacity-50 text-white rounded-l ${
            startIndex === maxStartIndex
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-opacity-70"
          }`}
        >
          <ChevronRight size={24} />
        </button>

        {/* Products Grid */}
        <div
          className={`grid ${
            isMobile ? "grid-cols-1" : "md:grid-cols-3"
          } gap-4 mt-5 text-black text-center overflow-hidden`}
        >
          {products
            .slice(startIndex, startIndex + visibleProducts)
            .map((product) => (
              <Link
                key={product.id}
                to={`/product/${product.id}`}
                state={{ item: product }}
                className="transition-all duration-300 ease-in-out px-4 md:px-0"
              >
                <div className="max-w-[80%] md:max-w-full mx-auto">
                  <img
                    src={`${ADMIN_URL}/images/product_images/medium/${product.main_image}`}
                    alt={product.product_name}
                    className="w-full h-auto object-cover transition-transform duration-500 ease-in-out hover:scale-110"
                  />
                </div>
              </Link>
            ))}
        </div>

        {/* Optional: Add indicators for mobile view */}
        {isMobile && (
          <div className="flex justify-center gap-2 mt-4">
            {products.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === startIndex ? "bg-white" : "bg-gray-400"
                }`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCarousel;
