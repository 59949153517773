import React, { useEffect, useState } from "react";
import axios from "axios";
import { ADMIN_URL, BASE_URL } from "../config/constants";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Product = () => {
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getMerch();
    getCategories();
  }, []);

  const getCategories = () => {
    let url = `${BASE_URL}/categories`;
    axios
      .get(url)
      .then((response) => {
        let data = response.data;
        setCategories(data.data);
      })
      .catch((error) =>
        toast.error(
          "An error occured, please check your connection and try again"
        )
      );
  };

  const getMerch = (id) => {
    let url = `${BASE_URL}/products`;
    axios
      .get(url)
      .then((response) => {
        let data = response.data;

        if (id) {
          let filteredData = data.data;

          filteredData = filteredData.filter((product) => {
            return product.category_id === id;
          });
          setProducts(filteredData);
        } else {
          setProducts(data.data);
        }
      })
      .catch((error) =>
        toast.error(
          "An error occured, please check your connection and try again"
        )
      );
  };
  // Filter products based on selected category
  const filteredProducts = (id) => {
    if (id === "ALL") {
      getMerch();
    } else {
      getMerch(id);
    }
  };

  // Categories to display

  return (
    <div className="min-h-[calc(100vh-200px)]">
      <div className="md:mx-5 mx-1 md:mt-20 mt-20">
        {/* Category Selection */}
        <div className="flex space-x-3 font-vcr-mono text-white mb-5 backdrop-blur-lg drop-shadow-lg shadow-xl">
          <div
            onClick={() => {
              setSelectedCategory("ALL");
              filteredProducts("ALL");
            }}
            className={`uppercase cursor-pointer px-2 py-1 ${
              selectedCategory === "ALL"
                ? "bg-white text-black"
                : "hover:bg-white/20"
            }`}
          >
            All
          </div>

          {categories.map((category) => (
            <div
              key={category.id}
              onClick={() => {
                setSelectedCategory(category);
                filteredProducts(category.id);
              }}
              className={`uppercase cursor-pointer px-2 py-1 ${
                selectedCategory === category
                  ? "bg-white text-black"
                  : "hover:bg-white/20"
              }`}
            >
              {category.name}
            </div>
          ))}
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 text-black text-center  ">
          {products.map((product, index) => (
            <Link
              key={index}
              to={`/product/${product.id}`}
              state={{ item: product }}
              className="transition-all duration-300 ease-in-out px-4 md:px-0 mb-4"
            >
              <div className="max-w-[80%] md:max-w-full mx-auto">
                <img
                  src={`${ADMIN_URL}/images/product_images/medium/${product.main_image}`}
                  alt={product.product_name}
                  className="w-full h-auto object-cover transition-transform duration-500 ease-in-out hover:scale-110"
                />
                <div className="mt-2 font-vcr-mono uppercase text-white">
                  <h3 className="font-bold text-[15px] md:text-lg">
                    {product.product_name}
                  </h3>
                  <p className="font-semibold text-[15px] md:text-lg">
                    ${product.price.toFixed(2)}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
