import React, { useEffect, useRef } from "react";
import { IKVideo, IKContext } from "imagekitio-react";

import { Link } from "react-router-dom";

export default function HeroSection() {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      // Ensure the video is muted before playing
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.log("Autoplay prevented:", error);
        // Optionally, you can handle the error by showing a play button or some fallback UI
      });
    }
  }, []);
  return (
    <div className="relative md:mx-5 mx-1 md:pt-20 pt-20">
      {/* <video
        className=" metallic-border"
        ref={videoRef}
        loop
        autoPlay
        muted
        playsInline
      >
        <source
          src="https://ik.imagekit.io/mmnldm/Dnmgdx1.mp4?updatedAt=1731199980492"
          type="video/mp4"
        />
      </video> */}
      <div className="relative w-full">
        <IKContext urlEndpoint="https://ik.imagekit.io/mmnldm/">
          <IKVideo
            className="ikvideo-default rounded-3xl w-full"
            path="Dnmgdx1.mp4"
            ref={videoRef}
            loop
            autoPlay
            muted
            playsInline
          />
        </IKContext>

        <div className="absolute inset-5 flex items-end justify-center">
          <Link
            to="/collection"
            className="text-white font-vcr-mono uppercase md:px-6 px-3 py-2 rounded-lg md:text-xl text-xs font-bold hover:bg-opacity-75 transition duration-300 underline"
          >
            DISCOVER
          </Link>
        </div>
      </div>
    </div>
  );
}
