import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "./CartContext";
import { ADMIN_URL } from "../config/constants";

export default function CartModal({ onClose }) {
  const { cartItems, removeFromCart, updateQuantity, getCartTotal } = useCart();

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={(e) => {
        // Close modal when clicking the backdrop
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full mx-3">
        <div className="p-5">
          {/* Header with Close Button */}
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-black">Your Cart</h2>
            <button onClick={onClose} className="text-gray-500 text-2xl">
              &times;
            </button>
          </div>

          {/* Cart Items */}
          {cartItems.length > 0 ? (
            <div className="my-4">
              {cartItems.map((item) => {
                return (
                  <div
                    key={`${item.id}-${item.size}-${item.color}`}
                    className="flex items-center justify-between py-2 border-b"
                  >
                    <div className="flex items-center space-x-4">
                      <img
                        src={`${ADMIN_URL}/images/product_images/medium/${item.main_image}`}
                        alt={item.product_name}
                        className="w-16 h-16 object-cover"
                      />
                      <div className="text-black">
                        <h3 className="font-semibold">{item.product_name}</h3>
                        <p className="text-sm">
                          Size: {item.size} | Color: {item.color}
                        </p>
                        <p className="text-sm">${item.price.toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <input
                        type="number"
                        min="1"
                        value={item.quantity}
                        onChange={(e) =>
                          updateQuantity(
                            item.id,
                            item.size,
                            item.color,
                            e.target.value
                          )
                        }
                        className="w-16 p-1 border text-black"
                      />
                      <button
                        onClick={() =>
                          removeFromCart(item.id, item.size, item.color)
                        }
                        className="text-red-500"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-center text-black my-5">
              You have no items in your cart
            </p>
          )}

          {/* Subtotal */}
          <div className="flex justify-between items-center border-t pt-3">
            <span className="text-black">Subtotal</span>
            <span className="text-black">${getCartTotal().toFixed(2)}</span>
          </div>

          {/* Checkout Button */}
          {cartItems.length > 0 && (
            <div className="text-center mt-5">
              <Link
                to="/checkout"
                className="bg-black text-white py-3 px-4 rounded-md inline-block"
                onClick={onClose}
              >
                Continue to Checkout
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
