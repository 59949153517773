import React from "react";
import Layout from "../components/Layout";
import ProductDetails from "../components/ProductDetails";
import { useLocation } from "react-router-dom";

function ProductDetailsPage() {
  const location = useLocation();
  const { item } = location.state;

  return (
    <Layout>
      <ProductDetails details={item} />
    </Layout>
  );
}

export default ProductDetailsPage;
