import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import BackgroundVideo from "../components/BackgroundVideo";
import HeroSection from "../components/HeroSection";
import Modal from "../components/Modal";
import Card from "../components/ProductCarousel";
import Layout from "../components/Layout";
import Footer from "../components/Footer";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the modal has already been shown
    const modalShown = localStorage.getItem("modalShown");

    if (!modalShown) {
      // Set a timer to open the modal 3 seconds after the page loads
      const timer = setTimeout(() => {
        setIsModalOpen(true);
        // Mark the modal as shown in localStorage
        localStorage.setItem("modalShown", "true");
      }, 3000);

      // Clean up the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Layout>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Header />
      <HeroSection />
      <Card />
    </Layout>
  );
}
