import React from "react";
import { useEffect, useRef } from "react";
import { IKVideo, IKContext } from "imagekitio-react";

export default function BackgroundVideo() {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      // Ensure the video is muted before playing
      videoRef.current.muted = true;
      videoRef.current.play().catch((error) => {
        console.log("Autoplay prevented:", error);
        // Optionally, you can handle the error by showing a play button or some fallback UI
      });
    }
  }, []);
  return (
    <div>
      {/* <video ref={videoRef} loop autoPlay muted playsInline id="bg-video">
        <source src={require("../vid/bg-vid.mp4")} type="video/mp4" />
      </video> */}
      <IKContext urlEndpoint="https://ik.imagekit.io/mmnldm/">
        <IKVideo
          className="ikvideo-default"
          id="bg-video"
          path="Bg-Vid.mp4"
          ref={videoRef}
          loop
          autoPlay
          muted
          playsInline
          loading="lazy"
        />
      </IKContext>
    </div>
  );
}
