import React from "react";

const AboutYProject = () => {
  return (
    <div className="backdrop-blur-lg drop-shadow-lg shadow-xl p-8 rounded-md max-w-2xl mt-20 mx-2 sm:mx-4 md:mx-auto text-[12px] text-white font-vcr-mono uppercase">
      <h2 className="text-lg font-semibold mb-4 uppercase">About DNMGD</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-6 mb-8">
        <div className="col-span-1">
          <p>
            Since the launch of DNMGD in 2022, Nigeria’s first denim-focused
            brand has pushed the boundaries of craftsmanship and cultural
            expression under the creative direction of Tony Bankong, the
            visionary behind GOD LEVEL. This brand combines innovative denim
            techniques with bold silhouettes.
          </p>
        </div>

        <div className="col-span-1">
          <p>
            At the core of DNMGD, Bankong has established a focus on
            individuality and authenticity. Fusing the rugged energy of
            streetwear with modern tailoring, his vision redefines denim through
            versatile, gender-fluid designs that celebrate both tradition and
            contemporary luxury.
          </p>
        </div>

        <div className="col-span-1">
          <p>
            In 2023, DNMGD debuted its first collection and hosted its inaugural
            fashion show, Dystopian Effect, an event that captivated audiences
            with its daring take on denim and futuristic storytelling. This
            pivotal year marked a new chapter for the brand.
          </p>
        </div>

        <div className="col-span-1">
          <p>
            Bankong's contemporary expression has created a fascinating
            narrative for the brand, leading to remarkable growth and widespread
            acclaim.
          </p>
        </div>

        <div className="col-span-1">
          <img
            src="/img/account.png"
            alt="User Account"
            className="rounded-md mb-2 w-[2.25rem] h-[2.25rem]"
          />
        </div>
      </div>

      <div className="border-t border-white my-6">
        <div className="flex flex-col sm:justify-between gap-6 py-4">
          <div className="pb-4 sm:pb-0 sm:border-b-0 border-b border-gray-300">
            <h3 className="font-bold">2023</h3>
            <p>DNMGD first collection and show: Dystopian Effect.</p>
          </div>
          <div className="pt-4 sm:pt-0 border-b-0 border-gray-300">
            <h3 className="font-bold">2022</h3>
            <p>The Birth of DNMGD</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutYProject;
