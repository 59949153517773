import React, { useState } from "react";
import { ADMIN_URL } from "../config/constants";
import { useCart } from "./CartContext";

// Hardcoded sizes and colors for UI example
const sizes = ["S", "M", "L"];
const colors = ["Black", "White"];

export default function ProductDetails({ details }) {
  const { addToCart } = useCart();
  const [selectedSize, setSelectedSize] = useState(sizes[0]);
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = () => {
    addToCart(details, quantity, selectedSize, selectedColor);
  };

  return (
    <div className="container mx-auto my-10 flex flex-col md:flex-row md:mt-20 mt-20 text-white">
      {/* Product Image Section */}
      <div className="md:w-1/2 p-5">
        <img
          src={`${ADMIN_URL}/images/product_images/medium/${details.main_image}`}
          alt={details.product_name}
          className="w-full h-auto"
        />
      </div>

      {/* Product Details Section */}
      <div className="md:w-1/2 p-5 mt-10 mx-auto font-vcr-mono bg-black bg-opacity-10">
        <div className="px-10 py-10 backdrop-blur-lg drop-shadow-lg shadow-xl">
          <h1 className="text-3xl font-bold mb-4 font-Neuehaasdisplay tracking-[2px] font-vcr">
            {details.product_name}
          </h1>
          <p className="text-2xl font-semibold text-cream mb-4">
            ${details.price.toFixed(2)}
          </p>

          <h3 className="font-semibold text-lg">Description:</h3>
          <p className="text-sm font-semibold text-grey mb-4">
            {details.description}
          </p>

          <div>
            {/* Size Options */}
            <div className="mb-4">
              <h3 className="font-semibold text-lg">Select Size:</h3>
              <select
                className="border mt-2 p-2 px-4 bg-black rounded-sm"
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                {sizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            {/* Color Options */}
            <div className="mb-4">
              <h3 className="font-semibold text-lg">Select Color:</h3>
              <select
                className="border mt-2 p-2 px-4 bg-black rounded-sm"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
              >
                {colors.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </div>

            {/* Quantity Selector */}
            <div className="mb-4">
              <h3 className="font-semibold text-lg">Quantity:</h3>
              <input
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                type="number"
                defaultValue="1"
                min="1"
                className="border mt-2 p-2 text-black w-20"
              />
            </div>
          </div>
          {/* Add to Cart Button */}
          <button
            onClick={handleAddToCart}
            className="bg-black text-white px-4 py-2 rounded-md mt-4"
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}
