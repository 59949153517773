import React from "react";
import { motion } from "framer-motion";
import { X } from "lucide-react";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 25 }}
        className="bg-black bg-opacity-50 backdrop-blur-lg drop-shadow-lg w-full max-w-md p-6 rounded-lg shadow-lg relative mx-2"
      >
        {/* Cancel Button */}
        <X
          onClick={onClose}
          className="absolute top-2 right-2 text-white cursor-pointer hover:text-gray-900"
        >
          ✖
        </X>

        {/* Modal Content */}
        <h2 className="text-lg font-bold mb-4 font-vcr text-white tracking-wide">
          Promotions and events
        </h2>
        <p className="mb-4 font-vcr-mono text-white">
          Sign up to our newsletter now for 10% off your first order and to be
          the first to know about exclusive items and seasonal drops!
        </p>
        <form className="flex items-center space-x-2">
          <input
            type="email"
            placeholder="Enter e-mail address"
            className="border p-2 rounded-md w-full"
          />
          <button
            type="submit"
            className="bg-black text-white font-vcr py-2 px-4 rounded-md min-w-[100px] whitespace-nowrap placeholder:font-vcr"
          >
            Sign up
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default Modal;
